import React, { useState, useEffect, useRef } from "react";
import { Icon } from 'leaflet';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import './leafletmap.css'

import markerIconPng from './fm7-marker-icon.png';

/*
 * 
https://stackoverflow.com/questions/65546744/not-able-to-change-center-dynamically-in-react-leaflet-v-3-x
https://codesandbox.io/s/how-to-change-center-dynamically-in-react-leaflet-v3x-d8rn7?file=/src/App.js:837-866

https://github.com/andrewl/gatsby-geo-simple-map/blob/master/src/pages/index.js
React Leaflet tutorial
https://blog.logrocket.com/react-leaflet-tutorial/
Add All Store Locations to a React Leaflet Map with Location Data From GraphCMS
https://egghead.io/lessons/graphcms-add-all-store-locations-to-a-react-leaflet-map-with-location-data-from-graphcms

 */

const defaultLocations = 
  { name: "FM7 Melbourne", position: [-37.819280,145.164060], popdesc: "<p>Addr. 8 King St. Blackburn. Vic. 3130.<br/> Phone. 03 8838 9994<br/> email. fm7nunawading@gmail.com</p>" }
;

const locations = [
  { name: "FM7 Nunawading", position: [-37.819280,145.164060], popdesc: "<p>Addr. 8 King St. Blackburn. Vic. 3130.<br/> Phone. 03 8838 9994<br/> email. fm7nunawading@gmail.com</p>" },
  { name: "FM7 Clayton", position: [-37.900780,145.124510], popdesc: "<p>Addr. 2/498 Clayton Rd, Clayton South, VIC 3169<br/> Phone. 03 8522 8815<br/> email. fm7clayton@gmail.com</p>"},  
];

function SetViewOnClick({ coords }) {
  const map = useMap();
  map.setView(coords, map.getZoom());

  return null;
}

function LeafletMap() {
  const [activeLocation, setActiveLocation] = useState();
  const markerRef = useRef();

  const defaultCenter = [-37.845718, 145.044324];
  const zoom = 11;

  const [coords, setCoords] = useState(defaultCenter);

  useEffect(() => {
    setActiveLocation(defaultLocations);
    markerRef.current?.openPopup();
  }, [])

  const handleLocationClick = (location) => {
    setActiveLocation(location);
    setCoords(location.position);
  };

  if (typeof window !== 'undefined') {
    const markerIcon = new Icon({
        iconUrl: markerIconPng
      });

    return (
    <section>      
    <div className="container container__tight leafletmap">
      <div className="single">
        <strong>Please select one of following branches</strong>
        <h3 style={{ marginBottom: "10px" }} >Melbourne</h3>
        <ul style={{ marginTop: "10px", marginBottom: "10px" }}>
          {locations.map((location) => (
            <li key={location.name} onClick={() => handleLocationClick(location)}>
              <button>{location.name}</button>
            </li>
          ))}
        </ul>
        
        {activeLocation && (
          <div>
            <h2>{activeLocation.name}</h2>
            <div dangerouslySetInnerHTML={{ __html: activeLocation.popdesc }} />
          </div>
        )}
      </div>
      <div className="double">
        <MapContainer center={defaultCenter} zoom={zoom} >
          <TileLayer url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png"  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'/>
            {locations.map((location) => (
              <Marker key={location.name} position={location.position} icon={markerIcon}>
                <Popup>
                  <h4>{location.name}</h4>
                  <div dangerouslySetInnerHTML={{ __html: location.popdesc }} />
                </Popup>
              </Marker>
            ))}

          {/* If you want display one per click 
          {activeLocation && (
             <Marker key={activeLocation.name} position={activeLocation.position} icon={
              new Icon({
                iconUrl: markerIconPng,
              })
            }>
              <Popup open={true}>
                <h4>{activeLocation.name} {activeLocation.position}</h4>
                <div dangerouslySetInnerHTML={{ __html: activeLocation.popdesc }} />
              </Popup>
           </Marker>
           )} */}

           <SetViewOnClick coords={coords} /> 
        </MapContainer>         
      </div>      
    </div>
    </section>
    );
  }else{
    return (
        <section>
            Nothing to return 
        </section>
    );
  }
}

export default LeafletMap;
