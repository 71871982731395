import * as React from "react"
// import Button from "../Button/Button"
import { BasicTextModuleStyles } from "./BasicTextModuleStyles"
import { BasicTextStyles, BasicTextRightStyles } from "./BasicTextModuleStyles"
// import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const BasicTextModule3 = ({ title, content, link, linkText }) => {
  return (
    <BasicTextModuleStyles className="section">
      <div className="container container__tight">
        <BasicTextStyles>
          {title && <h2>{title}</h2>}

          <h3>Melbourne</h3>
          <h4>FM7 Nunawading Branch</h4>
          <p className="content-wrap" style={{ marginBottom: "10px" }} dangerouslySetInnerHTML={{ __html: content }} />
          
          <h4>FM7 Clayton Branch</h4>
          <p>Address. 2/498 Clayton Rd, Clayton South, VIC 3169<br/> Phone. 03 8522 8815<br/> Email. fm7clayton@gmail.com</p>          
          
        </BasicTextStyles>
        <BasicTextRightStyles>
          <StaticImage
            src="../../../static/profile.jpg"
            alt="Perk Image"
            layout="constrained"
            placeholder="tracedSVG"
          />
        </BasicTextRightStyles>
      </div>
    </BasicTextModuleStyles>
  )
}

export default BasicTextModule3
