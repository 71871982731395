import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { PerksModuleStyles2 } from "./PerksModuleStyles"
// import { MdOutlineClose as Cross } from "react-icons/md"
// import Perk from "./Perk"

const Perks2Module = () => {
  return (
    <PerksModuleStyles2 className="section section__padding">    
      <div className="container container__tight">               
          <div>
            <h3>Car and House Window Tinting Melbourne</h3>
            <p>FM7 offers exclusive eco-friendly heat rejection Carbon and Ceramic films for car window tinting in Melbourne and world-class house window tinting Melbourne wide. We, having the state of the art technology and the best product process, manufacture and sell high quality coating films to domestic and international markets.</p>
            <p>Our world-class tinting films are best fit for the building and car interior and we make sure they are energy efficient and eco-friendly. We put our top priority on original technology and select only the best materials to maintain our status as a super brand. Car tints Melbourne can keep your car up to 60% cooler even when parked right out there in the scorching sun. Quality car tint blocks up to 99% of UV rays, so you won’t have to worry about skin cancer, natural skin damage, or accelerated ageing.</p>
            <p>They prevent sun glare, snow glare, and even headlight glare in the night-time. Window tinting Melbourne can secure high visibility in your window. The hyper-transparent PET material and high-tech nano-ceramic material can give you clear and clean sight without any dazzling eyes. The house window tinting solutions are highly beneficial bringing benefits such as advancing the security and privacy of your home depending on the shade of tint no one will be able to look into your house.</p>
            <p>There is no interruption of running electronic equipment such as mobile phone and car navigation in your car or in your building. The window tinting also offers the reduction of glares and UV rays; while also enhance the aesthetics of your property. The ceramic-based tinting films don’t use the low-priced materials so that we can provide a long time functionality in your building. It can prevent the furniture and interiors inside the window from being decoloured. In addition, this technology can protect your family’s healthy skin from the harmful sunlight and can save your energy cost by 35% at maximum.</p>
          </div>

          <StaticImage
            src="../../../static/windows.png"
            alt="Perk Image"
            layout="constrained"
            placeholder="tracedSVG"
          />
      </div>
    </PerksModuleStyles2>
  )
}

export default Perks2Module
